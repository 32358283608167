import React, { useRef } from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import get from "lodash/get";
import Navigation from "../components/Navigation";
import VideoCover from "../components/VideoCover";
import "../style/all.css";
import smoothscroll from "smoothscroll-polyfill";
import { nodeName } from "jquery";

export default function IndexPage(props) {
  const siteTitle = get(this, "props.data.site.siteMetadata.title");
  // const posts = get(this, 'props.data.allWordpressPost.edges')
  if (typeof window !== "undefined") {
    smoothscroll.polyfill();
  }

  var projekteObject = {};
  const helloRef = useRef(null);
  let projekte = props.data.allContentfulProject.nodes;
  let neuigkeiten = props.data.allContentfulNeuigkeit.nodes;
  const homepage = props.data.allContentfulHomepage.nodes[0];
  const termine = props.data.contentfulTermine;

  // posts.forEach(({ node }) => {
  //   var filteredProjektePosts = node.categories.filter(
  //     category => category.name === 'Projekte'
  //   )
  //   var filteredNeuigkeitenPosts = node.categories.filter(
  //     category => category.name === 'Neues'
  //   )
  //   if (filteredProjektePosts.length > 0) {
  //     projekte.push(node)
  //   }
  //   if (filteredNeuigkeitenPosts.length > 0) {
  //     neuigkeiten.push(node)
  //   }
  // })

  function compare(a, b) {
    if (a.date < b.date) return 1;
    if (a.date > b.date) return -1;
    return 0;
  }

  neuigkeiten.sort(compare);
  neuigkeiten = neuigkeiten.slice(0, 3);

  // const vita = get(this, 'props.data.allWordpressPage.edges').filter(
  //   post => post.node.title === 'Vita'
  // )[0]
  // const kontakt = get(this, 'props.data.allWordpressPage.edges').filter(
  //   post => post.node.title === 'Kontakt'
  // )[0]

  // console.log("projekte 1: ", projekte);
  projekte.forEach(function (item) {
    item.date = item.date?.slice(0, 4);
    if (!projekteObject[item.date]) {
      projekteObject[item.date] = [];
    }
    projekteObject[item.date].push(item);
  });
  // console.log("projekteObject", projekteObject)

  const blatoday = new Date();
  const thisYear = blatoday.getFullYear();

  function thumbnail(postNode) {
    if (postNode) {
      // console.log("this is the postNode: ", postNode)
      if (
        postNode.thumbnail?.file?.url !== null &&
        postNode.thumbnail?.file?.url !== undefined
      ) {
        return (
          <div
            style={{
              marginLeft: 0,
              marginRight: "10px",
              padding: "0px",
            }}
          >
            <Link
              className="thumbnail-link"
              to={"projekte/" + postNode.slug}
              style={{
                padding: 0,
                margin: 0,
                boxShadow: "none",
                maxHeight: "150px",
                border: "none",
              }}
            >
              <img
                src={postNode.thumbnail.fixed.src}
                style={{ margin: "0px", padding: "0px" }}
                className="thumbnail-image"
              />
            </Link>
          </div>
        );
      } else {
        return (
          <div
            className="fakeThunbnail"
            style={{ width: 0, height: 0, margin: 0, padding: 0 }}
          ></div>
        );
      }
    }
  }

  function featuredMedia(postNode) {
    if (postNode) {
      if (
        postNode.thumbnail.file.url !== null &&
        postNode.thumbnail.file.url !== undefined
      ) {
        return <img fluid={postNode.thumbnail.file.url} />;
      } else {
        return (
          <div
            className="fakeThunbnail"
            style={{ width: 0, height: 0, margin: 0, padding: 0 }}
          ></div>
        );
      }
    }
  }

  let projectCount = 0;

  return (
    <div>
      <Helmet title="Adrienn Bazsó">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
      </Helmet>
      {homepage.videoAnzeigen && (
        <VideoCover
          video={homepage.video.file}
          logo={homepage.logo.file}
          featuredProject={homepage.featuredProject}
          featuredProjectAnzeigen={homepage.featuredProjectAnzeigen}
          scrollRef={helloRef}
          offSet={typeof window !== "undefined" ? window.innerHeight : 0}
        />
      )}
      <div
        className="container"
        style={{
          position: "relative",
          zIndex: 200,
          display: "block",
          /*top: calc(100vh - 44px);*/
          top: homepage.videoAnzeigen ? "100vh" : 0,
          backgroundColor: "white",
          margin: 0,
          padding: 0,
        }}
      >
        <Navigation />
        <div ref={helloRef} className="content-wrapper">
          <section className="section-hallo">
            {homepage.videoAnzeigen ? (
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: homepage.intro.childMarkdownRemark.html,
                }}
              ></div>
            ) : (
              <div className="content">
                <img
                  src={homepage.logo.file.url}
                  height="85px"
                  style={{
                    display: "inline",
                    transform: "translateY(15px)",
                    margin: 0,
                    padding: 0,
                  }}
                ></img>
                <h1 style={{ display: "inline" }}>
                  ist freischaffende Schauspielerin und Theaterpädagogin in
                  Berlin.
                </h1>
                <h2>Willkommen! Treten Sie näher.</h2>
              </div>
            )}
          </section>

          <section className="section-neues">
            <div className="content">
              <h1>Termine</h1>
              <div
                className="section-content"
                dangerouslySetInnerHTML={{
                  __html: termine.textblock.childMarkdownRemark.excerpt,
                }}
              />
              <Link className="link" to="/termine">
                Alle Termine →
              </Link>
            </div>
          </section>

          <section className="section-projekte">
            <div className="content">
              <h1>Letzte Projekte</h1>
              {Object.keys(projekteObject)
                .sort(function (a, b) {
                  return b - a;
                })
                .map((year) => {
                  const postsFromThisYear = projekteObject[year];
                  // console.log("projekte: ", projekteObject);
                  // if (year == thisYear) {
                  //     year = "Aktuelle Projekte"
                  // }

                  return (
                    <div key={year}>
                      <div
                      // className="section-content"
                      >
                        {postsFromThisYear.map((node) => {
                          projectCount += 1;

                          if (projectCount < 4) {
                            return (
                              <div
                                className="list-entry"
                                key={node.slug}
                                //   style={{margin: 0, padding: 0}}
                              >
                                {thumbnail(node)}
                                <div
                                  className="project-list-text"
                                  style={{ margin: 0 }}
                                >
                                  <h2
                                    style={{
                                      marginTop: 0,
                                      marginLeft: 0,
                                      marginRight: 0,
                                    }}
                                  >
                                    <Link
                                      style={{
                                        margin: 0,
                                        verticalAlign: "top",
                                      }}
                                      to={"projekte/" + node.slug}
                                      dangerouslySetInnerHTML={{
                                        __html: node.title,
                                      }}
                                    ></Link>
                                  </h2>
                                  <p
                                    style={{
                                      fontStyle: "italic",
                                      paddingBottom: 0,
                                      marginBottom: "1em",
                                    }}
                                  >
                                    {node?.kategorie?.[0].title ?? ""}
                                  </p>

                                  <div>
                                    {node.text.childMarkdownRemark.excerpt}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}

              <Link className="link" to="/projekte">
                Alle Projekte →
              </Link>
            </div>
          </section>

          <section className="section-vita">
            <div className="content">
              {/* {featuredMedia(vita.node)} */}
              <div className="section-content">
                <div
                  className="blog-content"
                  dangerouslySetInnerHTML={{
                    __html: homepage.aboutSummary.childMarkdownRemark.html,
                  }}
                />
              </div>
              <Link className="link" to="/vita">
                Mehr erfahren →
              </Link>
            </div>
          </section>

          <section className="section-kontakt">
            <div className="content">
              <div
                className="blog-content"
                dangerouslySetInnerHTML={{
                  __html: homepage.kontakt.childMarkdownRemark.html,
                }}
              />
              <Link className="link" style={{ color: "white" }} to="/kontakt">
                Los geht's →
              </Link>
            </div>
          </section>
        </div>
        <footer
          style={{
            textAlign: "center",
            paddingTop: "5px",
            background: "black",
            color: "white",
          }}
          className="Footer"
        >
          <p
            style={{
              marginBottom: "0",
              paddingBottom: "5px",
              fontSize: "12px",
            }}
          >
            {homepage.footerText + " "}•{" "}
            <Link
              style={{
                color: "white",
              }}
              to="/impressum"
            >
              Impressum
            </Link>
          </p>
        </footer>
      </div>
    </div>
  );
}

export const query = graphql`
  {
    contentfulTermine(id: { eq: "249466d6-0c19-5854-90dc-fabb0f58112f" }) {
      textblock {
        childMarkdownRemark {
          excerpt(format: HTML, truncate: true, pruneLength: 100)
        }
      }
    }
    allContentfulHomepage {
      nodes {
        id
        aboutSummary {
          childMarkdownRemark {
            html
          }
        }
        createdAt
        updatedAt
        logo {
          file {
            url
          }
        }
        kontakt {
          childMarkdownRemark {
            html
          }
        }
        intro {
          childMarkdownRemark {
            html
          }
        }
        impressum {
          childMarkdownRemark {
            html
          }
        }
        featuredProjectAnzeigen
        featuredProject {
          slug
          title
        }
        footerText
        videoAnzeigen
        video {
          file {
            url
            contentType
          }
        }
      }
    }
    allContentfulNeuigkeit {
      nodes {
        date(formatString: "dddd, DD. MMMM YYYY", locale: "de")
        title
        text {
          childMarkdownRemark {
            html
            excerpt
          }
        }
        slug
      }
    }
    allContentfulProject {
      nodes {
        date
        kategorie {
          title
        }
        slug
        subtitle {
          subtitle
        }
        text {
          childMarkdownRemark {
            html
            excerpt
          }
        }
        thumbnail {
          file {
            url
          }
          fixed(width: 300, height: 300) {
            src
          }
        }
        title
        updatedAt
      }
    }
  }
`;
