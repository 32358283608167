import React from "react";
import Link from "gatsby-link";
import BackgroundVideoPreview from "../img/poster.png";
import Mp4BackgroundVideo from "../video/video.mp4";
import Logo from "../img/logo.png";
import DownArrow from "../img/downArrow.svg";
import FBShape from "../img/FBShape.svg";
import YTShape from "../img/YTShape.svg";
import IGShape from "../img/IGShape.svg";

export default (props) => {
  return (
    <div id="home" className="menu white">
      <video
        playsInline
        autoPlay
        muted
        loop
        // poster={ BackgroundVideoPreview }
        id="bgvid"
      >
        <source
          // src={Mp4BackgroundVideo + "#t=12,96"}
          src={props.video.url}
          type={props.video.contentType}
        ></source>
      </video>

      <div className="videoBackground">
        <img
          className="logo"
          // src={Logo}
          src={props.logo.url}
        ></img>
        {props.featuredProjectAnzeigen && (
          <h2 className="slogan">
            <Link
              // to='projekte/krieg-stell-dir-vor-er-waere-hier-2/'
              to={`projekte/${props.featuredProject.slug}`}
            >
              {/* Krieg. Stell dir vor er wäre hier */}
              {props.featuredProject.title}
            </Link>
          </h2>
        )}
        {/* <div className="social-media-links">
          <a href="https://www.facebook.com/adrienn.bazso.7" target="blank">
            <figure>
              <img src={FBShape} />
            </figure>
          </a>
          <a href="https://www.youtube.com/user/bazsoadri/" target="blank">
            <figure>
              <img src={YTShape} />
            </figure>
          </a>
          <a href="https://www.instagram.com/bazsoadriennvirag/" target="blank">
            <figure>
              <img src={IGShape} />
            </figure>
          </a>
        </div> */}
        <button
          className="down-arrow"
          onClick={
            () =>
              window.scrollTo({
                // top: props.scrollRef.current.offsetTop - 20,
                top: props.offSet,
                behavior: "smooth",
              }) // General scroll to element function
          }
        >
          <img src={DownArrow} />
        </button>
      </div>
    </div>
  );
};
